import React from "react"
import Page from "../components/Page"

export default function Home() {
  return (
    <Page>
      <div>Hello world!</div>
    </Page>
  )
}
